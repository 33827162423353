export default {
  Menu: {
    Home : 'Home',
    Contact: 'Kontakt'
  },
  SiteHeader:{
    MainMessage : 'Willkommen',
    Motto: 'Alles ist möglich',
    ShortAboutMe: 'Softwareentwicklung ist meine Leidenschaft'
  },
  Home: {
    SkillSet: 'Skills',
    Projects: 'Projekte'
  },
  Project: {
    Role: 'Rolle',
    Branch: 'Branche',
    Tasks: 'Ausgaben',
    SystemAndMethods: 'Systeme und Methoden'
  },
  Contact: {
    Header: 'Haben Sie noch Fragen?',
    SubHeader: 'Kontaktieren Sie ',
    LinkTitle: 'mich'
  }
};