import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'semantic-ui-react';
import Project from './Project';


class ProjectList extends React.Component{
  render (){
    const rows = [];
    this.props.projects.forEach((project, i) => {
      rows.push(<Project key={i} project={project} />);
    });

    return(
      <Card.Group itemsPerRow='1'>
        {rows}
      </Card.Group>
    );
  }
}

ProjectList.propTypes = {
  projects: PropTypes.array
};

export default ProjectList;