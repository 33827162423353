import React from 'react';
import PropTypes from 'prop-types';

import SkillData from '../Data/SkillData';
import ProjectsData from '../Data/ProjectsData';
import Skillset from './Skillset/Skillset';
import Experience from './Projects/Experience';

class Home extends React.Component {
  constructor(props) {
    super(props);
    
    this.locale = this.props.language;
    this.skills = SkillData.Skills;
    this.projects = ProjectsData;
    
  }

  getProjectData(){
    let data;
    
    switch (this.locale) {
    case 'en-US':
      data = this.projects.ProjectsEN;
      break;
    case 'de-DE':
      data = this.projects.ProjectsDE;
      break;
    default:
      data = this.projects.ProjectsDE;
      break;
    }

    return data;
  }

  render() {
    const data = this.getProjectData();
    return (
      <section>
        <Skillset skills={this.skills} />
        <Experience projects={data} />
      </section>
    );
  }
}

Home.propTypes = {
  language: PropTypes.string
};

export default Home;
