import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Grid, Header } from 'semantic-ui-react';
import ProjectList from './ProjectList';

class Experience extends React.Component{
  constructor(props) {
    super(props);
  }

  render(){
    return(
      <div className='App-experience'>
        <Grid verticalAlign='middle'>
          <Grid.Row only='mobile'>
            <Grid.Column>
              <Header as='h2' textAlign='left' className='App-textColor-white'>
                <FormattedMessage id='Home.Projects' />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row only='mobile'>
            <Grid.Column>
              <ProjectList projects={this.props.projects} />
            </Grid.Column>
          </Grid.Row>

          <Grid.Row only='computer'>
            <Grid.Column width={2}>
              <Header as='h2' className='App-textColor-white'>
                <FormattedMessage id='Home.Projects' />
              </Header>
            </Grid.Column>
            <Grid.Column width={14}>
              <ProjectList projects={this.props.projects} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

Experience.propTypes = {
  projects: PropTypes.array
};

export default Experience;