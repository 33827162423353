import React from 'react';
import PropTypes from 'prop-types';

import { List, Progress } from 'semantic-ui-react';

class Skill extends React.Component{
  constructor(props) {
    super(props);
  }

  render(){
    let skill = this.props.skill;
    return(
      <List.Item className='App-skillListItem'>
        <List.Header>{skill.name}</List.Header>
        <List.Content verticalAlign='middle'>
          <Progress  value={skill.rating} total='10' size='medium' />
        </List.Content>
      </List.Item>
    );
  }
}

Skill.propTypes = {
  skill: PropTypes.object
};

export default Skill;