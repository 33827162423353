import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Grid, Header, Image } from 'semantic-ui-react';
import MainMenu from './MainMenu';


class SiteHeader extends React.Component {
  constructor(props) {
    super(props);

    this.imageSrc = 'https://gravatar.com/avatar/7efea8cae7b1480d5968bb468b7540ee';
    this.imageWidth = 200;
    this.imageHref = this.imageSrc + '?s=' + this.imageWidth;
  }

  render() {
    return (
      <header className='App-header'>
        <Grid columns={2} textAlign='center'>
          <Grid.Row >
            <MainMenu language={this.props.language} changeLanguage={this.props.changeLanguage} />
          </Grid.Row>

          <Grid.Row columns='2' centered only='mobile' verticalAlign='middle'>
            <Grid.Column>
              <Image src={this.imageHref} alt='avatar' circular centered size='small' />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='2' centered only='mobile'>
            <Grid.Column>
              <Header as='h1' className='App-title App-textColor-white'>
                <FormattedMessage id='SiteHeader.MainMessage' />
              </Header>
              <em className='App-textColor-white'>
                <FormattedMessage id='SiteHeader.Motto' />
              </em>
              <Header as='h2' className='App-textColor-white'>
                <FormattedMessage id='SiteHeader.ShortAboutMe' />
              </Header>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns='2' centered only='computer' verticalAlign='middle'>
            <Grid.Column textAlign='center'>
              <Header as='h1' className='App-title App-textColor-white'>
                <FormattedMessage id='SiteHeader.MainMessage' />
              </Header>
              <em className='App-textColor-white'>
                <FormattedMessage id='SiteHeader.Motto' /></em>
              <Header as='h2' className='App-textColor-white'>
                <FormattedMessage id='SiteHeader.ShortAboutMe' />
              </Header>
            </Grid.Column>
            <Grid.Column>
              <Image src={this.imageHref} alt='avatar' circular centered size='small' />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </header>
    );
  }
}

SiteHeader.propTypes = {
  changeLanguage: PropTypes.func,
  language: PropTypes.string
};

export default SiteHeader;
