export default {
  Menu: {
    Home : 'Home',
    Contact: 'Contact'
  },
  SiteHeader :{
    MainMessage : 'Welcome',
    Motto: 'Everything is possible',
    ShortAboutMe: 'Software development is my passion'
  },
  Home: {
    SkillSet: 'Skillset',
    Projects: 'Projects'
  },
  Project: {
    Role: 'Role',
    Branch: 'Branch',
    Tasks: 'Tasks',
    SystemAndMethods: 'System and Methods'
  },
  Contact: {
    Header: 'Do you have any further questions?',
    SubHeader: 'Contact ',
    LinkTitle: 'Me'
  }
};