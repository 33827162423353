import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Flag, Menu, Responsive } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class MainMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = { 
      activeItem: 'home',
      selectedLanguage: this.props.language
    };

    this.changeLanguage = this.props.changeLanguage;
    this.handleMenuClick = (e, { name }) => this.setState({ activeItem: name });
    this.handleLanguageClick = (e, { name }) => this.setState({ selectedLanguage: name });
    
  }

  // componentDidMount() {
  //   console.log('componentDidMount: ' + this.state.selectedLanguage);
  //   this.changeLanguage(this.state.selectedLanguage);
  // }

  componentDidUpdate(){
    // console.log('componentDidUpdate: ' + this.state.selectedLanguage);
    this.changeLanguage(this.state.selectedLanguage);
  }

  render () {
    const { activeItem } = this.state;
    const { selectedLanguage } = this.state;
    return (
      <nav>
        <Responsive {...Responsive.onlyMobile}>
          <Menu fluid borderless size='tiny' fixed='top' className='App-menu'>
            {/* <Menu.Item header>Liviu Cristea</Menu.Item> */}
            <Menu.Item as={Link} to='/' name='Home' active={activeItem === 'home'} onClick={this.handleMenuClick} >
              <FormattedMessage id='Menu.Home' />
            </Menu.Item>
            <Menu.Item as={Link} to='/contact' name='Contact' active={activeItem === 'contact'} onClick={this.handleMenuClick}>
              <FormattedMessage id='Menu.Contact' />
            </Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item name='de-DE' active={selectedLanguage === 'de-DE'} onClick={this.handleLanguageClick} className={ (selectedLanguage === 'de-DE') ? 'App-activeLang' : '' }>
                <Flag name='de' />
              </Menu.Item>
              <Menu.Item name='en-US' active={selectedLanguage === 'en-US'} onClick={this.handleLanguageClick} className={ (selectedLanguage === 'en-US') ? 'App-activeLang' : '' }>
                <Flag name='us' />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Responsive>
        <Responsive {...Responsive.onlyComputer}>
          <Menu fluid borderless size='tiny' fixed='top' className='App-menu'>
            <Menu.Item header>Liviu Cristea</Menu.Item>
            <Menu.Item as={Link} to='/' name='Home' active={activeItem === 'home'} onClick={this.handleMenuClick} >
              <FormattedMessage id='Menu.Home' />
            </Menu.Item>
            <Menu.Item as={Link} to='/contact' name='Contact' active={activeItem === 'contact'} onClick={this.handleMenuClick}>
              <FormattedMessage id='Menu.Contact' />
            </Menu.Item>
            <Menu.Menu position='right'>
              <Menu.Item name='de-DE' active={selectedLanguage === 'de-DE'} onClick={this.handleLanguageClick} className={ (selectedLanguage === 'de-DE') ? 'App-activeLang' : '' }>
                <Flag name='de' />
              </Menu.Item>
              <Menu.Item name='en-US' active={selectedLanguage === 'en-US'} onClick={this.handleLanguageClick} className={ (selectedLanguage === 'en-US') ? 'App-activeLang' : '' }>
                <Flag name='us' />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </Responsive>
      </nav>
    );
  }
}

MainMenu.propTypes = {
  changeLanguage: PropTypes.func,
  language: PropTypes.string
};

export default MainMenu;