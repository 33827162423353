import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Grid, Header } from 'semantic-ui-react';
import SkillList from './SkillList';

class Skillset extends React.Component{
  // constructor(props) {
  //   super(props);
  // }

  render(){
    return(
      <div className='App-skillset'>
        <Grid verticalAlign='middle'>
          <Grid.Row only='mobile'>
            <Grid.Column>
              <Header as='h2' textAlign='left'>
                <FormattedMessage id='Home.SkillSet' />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row only='mobile'>
            <Grid.Column>
              <SkillList isMobile skills={this.props.skills} />
            </Grid.Column>
          </Grid.Row>
          
          <Grid.Row only='computer'>
            <Grid.Column width={2}>
              <Header as='h2' >
                <FormattedMessage id='Home.SkillSet' />
              </Header>
            </Grid.Column>
            <Grid.Column width={14}>
              <SkillList skills={this.props.skills} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

Skillset.propTypes = {
  skills: PropTypes.array
};

export default Skillset;