export default class ProjectsData {}

ProjectsData.ProjectsDE = [
  {
    'title': 'Entwicklung einer Web-Anwendung zum Generieren von digitalen Zertifikaten für versicherte Kunden',
    'time_period': '01.05.2018 - heute',
    'company_name': 'AXA Corporate Solutions (über Telexiom AG)',
    'description': '',
    'role': 'Full-Stack Entwickler',
    'branch': 'Versicherung',
    'tasks': [
      'Anforderungsanalyse',
      'Dokumentation',
      'Konzeptionierung',
      'Datenbank-Administration',
      'Unterstützung des Deployment und Release Prozesses'
    ],
    'systems_and_methods': [
      '.NET 4.7.2',
      'Visual Studio 2015',
      'SVN',
      'Jenkins',
      'JIRA',
      'Oracle SQL Developer',
      'ASP.NET MVC',
      'Bootstrap',
      'HTML, CSS',
      'JavaScript, jQuery',
      'Modernizr'
    ]
  },
  {
    'title': 'Entwicklung einer SharePoint-basierte Lösung zur Umsetzung und Dokumentation der EU Datenschutz Grundverordnung',
    'time_period': '01.07.2017 - 31.04.2018',
    'company_name': 'Brüll & Partner GmbH',
    'description': '',
    'role': 'Technischer Leiter',
    'branch': 'Software Dienstleistung',
    'tasks': ['Anforderungsanalyse', 'Konzeptionierung'],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2017',
      'ReSharper',
      'Microsoft SharePoint Online CSOM',
      'Azure App Service',
      'Azure SQL Service',
      'ASP.NET MVC',
      'Bootstrap',
      'HTML, CSS',
      'JavaScript, jQuery',
      'Modernizr'
    ]
  },
  {
    'title': 'Entwicklung eines Personalbogens zur Erfassung/Dokumentation von zugeteilten Berechtigungen und Hardware',
    'time_period': '15.05.2017 - 31.07.2017',
    'company_name': 'Brüll & Partner GmbH',
    'description': '',
    'role': 'Entwickler',
    'branch': 'Software Dienstleistung',
    'tasks': ['Konzeptionierung', 'Entwicklung', 'Implementierung', 'Dokumentation', 'Automatisches Deployment der SharePoint Lösung'],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2017',
      'ReSharper',
      'Microsoft SharePoint CSOM',
      'SPMeta2',
      'SharePoint Workflows'
    ]
  },
  {
    'title': 'Entwicklung einer Schnittstelle zwischen Microsoft Dynamics NAV und SharePoint Online',
    'time_period': '01.04.2017 - 15.05.2017',
    'company_name': 'Brüll & Partner GmbH',
    'description': 'short project description',
    'role': 'Entwickler',
    'branch': 'Software Dienstleistung',
    'tasks': ['Entwicklung', 'Automatische Anmeldung an SharePoint Online', 'Automatische Einrichtung der Berechtigungen in SharePoint Online', 'Datenmigration nach SharePoint Online'],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2017',
      'ReSharper',
      'Microsoft SharePoint Online CSOM',
      'Azure Active Directory Graph API',
      'log4net',
      'Dependency Injection'
    ]
  },
  {
    'title': 'Erweiterung der bestehenden kundenindividuellen Projektseiten',
    'time_period': '01.02.2017 - 31.03.2017',
    'company_name': 'Brüll & Partner GmbH',
    'description': '',
    'role': 'Entwickler',
    'branch': 'Software Dienstleistung',
    'tasks': ['Entwicklung', 'Refactoring', 'Implementierung einer Konfigurationsmöglichkeit in SharePoint'],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2017',
      'ReSharper',
      'Microsoft SharePoint SSOM',
      'Microsoft SharePoint JSOM'
    ]
  },
  {
    'title': 'Weiterentwicklung der Produkt-Informations-Management Software MeDaPro',
    'time_period': '01.04.2016 - 31.01.2017',
    'company_name': 'ITB-GmbH',
    'description': '',
    'role': 'Entwickler',
    'branch': 'Software Dienstleistung',
    'tasks': [
      'Bugfixes', 
      'Code Reviews', 
      'Testing'
    ],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2015',
      'ReSharper',
      'Entity Framework',
      'Dependency Injection',
      'WPF',
      'Model View VieModel (MVVM) Pattern',
      'WCF Services'
    ]
  },
  {
    'title': '"Entwurf und Entwicklung automatischer Performancetest für die Produkt-Informations-Management Software MeDaPro',
    'time_period': '01.11.2015 - 31.03.2016',
    'company_name': 'ITB-GmbH',
    'description': '',
    'role': 'Entwickler und Tester',
    'branch': 'Software Dienstleistung',
    'tasks': [
      'Evaluierung bestehender PerformanceTest Werkzeuge für .NET unter Betrachtung der Automatisierungsfähigkeit', 
      'Analyse der Erweiterbarkeit von xUnit um die gewünschte Funktionalität', 
      'Implementierung einer Erweiterung für das xUnit-Framework und einer Konsolenanwendung für die Ausführung der Tests',
      'Implementierung einer Analysesoftware für die Auswertung der Testergebnisse'
    ],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2015',
      'ReSharper',
      'Dependency Injection',
      'xUnit',
      'Reflection',
      'Testautomatisierung',
      'Visual Studio Team Services Build Prozess',
      'Powershell'
    ]
  }
];
    
ProjectsData.ProjectsEN = [
  {
    'title': 'Development of a web application for generating digital certificates for insured customers',
    'time_period': '01.05.2018 - heute',
    'company_name': 'AXA Corporate Solutions (via Telexiom AG)',
    'description': '',
    'role': 'Full-Stack Developer',
    'branch': 'Insureance',
    'tasks': [
      'Requirements analysis',
      'Documentation',
      'Conceptual design',
      'Database-Administration',
      'Support of the deployment and release process'
    ],
    'systems_and_methods': [
      '.NET 4.7.2',
      'Visual Studio 2015',
      'SVN',
      'Jenkins',
      'JIRA',
      'Oracle SQL Developer',
      'ASP.NET MVC',
      'Bootstrap',
      'HTML, CSS',
      'JavaScript, jQuery',
      'Modernizr'
    ]
  },
  {
    'title': 'Development of a SharePoint based solution for the implementation and documentation of the EU General Data Protection Regulation',
    'time_period': '01.07.2017 - 31.04.2018',
    'company_name': 'Brüll & Partner GmbH',
    'description': '',
    'role': 'Project Leader',
    'branch': 'IT Services',
    'tasks': ['Requrements analysis', 'Conceptual design'],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2017',
      'ReSharper',
      'Microsoft SharePoint Online CSOM',
      'Azure App Service',
      'Azure SQL Service',
      'ASP.NET MVC',
      'Bootstrap',
      'HTML, CSS',
      'JavaScript, jQuery',
      'Modernizr'
    ]
  },
  {
    'title': 'Development of a personnel questionnaire for the registration/documentation of assigned authorizations and hardware',
    'time_period': '01.05.2017 - 31.07.2017',
    'company_name': 'Brüll & Partner GmbH',
    'description': '',
    'role': 'Developer',
    'branch': 'IT Services',
    'tasks': ['onceptual design','Development','Implementation','Documentation','Automatic deployment of the SharePoint Solution'],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2017',
      'ReSharper',
      'Microsoft SharePoint CSOM',
      'SPMeta2',
      'SharePoint Workflows'
    ]
  },

  {
    'title': 'Development of an interface between Microsoft Dynamics NAV and SharePoint Online',
    'time_period': '01.04.2017 - 15.05.2017',
    'company_name': 'Brüll & Partner GmbH',
    'description': '',
    'role': 'Developer',
    'branch': 'IT Services',
    'tasks': ['Development', 'Automatic login to SharePoint Online', 'Automatic setup of permissions in SharePoint Online', 'Data migration to SharePoint Online'],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2017',
      'ReSharper',
      'Microsoft SharePoint Online CSOM',
      'Azure Active Directory Graph API',
      'log4net',
      'Dependency Injection'
    ]
  },
  {
    'title': 'Extension of the existing customer-specific project pages',
    'time_period': '01.02.2017 - 31.03.2017',
    'company_name': 'Brüll & Partner GmbH',
    'description': '',
    'role': 'Developer',
    'branch': 'IT Services',
    'tasks': ['Development', 'Refactoring', 'Implementation of a configuration option in SharePoint'],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2017',
      'ReSharper',
      'Microsoft SharePoint SSOM',
      'Microsoft SharePoint JSOM'
    ]
  },
  {
    'title': 'Further development of the product information management software MeDaPro',
    'time_period': '01.04.2016 - 31.01.2017',
    'company_name': 'ITB-GmbH',
    'description': '',
    'role': 'Developer',
    'branch': 'IT Servives',
    'tasks': [
      'Bugfixes', 
      'Code Reviews', 
      'Testing'
    ],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2015',
      'ReSharper',
      'Entity Framework',
      'Dependency Injection',
      'WPF',
      'Model View VieModel (MVVM) Pattern',
      'WCF Services'
    ]
  },
  {
    'title': 'Design and development of automatic performance test for the product information management software MeDaPro',
    'time_period': '01.11.2015 - 31.03.2016',
    'company_name': 'ITB-GmbH',
    'description': '',
    'role': 'Developer and Tester',
    'branch': 'IT Services',
    'tasks': [
      'Evaluation of existing PerformanceTest tools for .NET under consideration of automation capability', 
      'Analysis of the extensibility of xUnit by the desired functionality', 
      'Implementation of an extension for the xUnit framework and a console application for executing the tests',
      'Implementation of an analysis software for the evaluation of the test results'
    ],
    'systems_and_methods': [
      '.NET 4.5.2',
      'Visual Studio 2015',
      'ReSharper',
      'Dependency Injection',
      'xUnit',
      'Reflection',
      'Test automation',
      'Visual Studio Team Services Build Prozess',
      'Powershell'
    ]
  }
];