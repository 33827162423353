import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Card, Header, List, Table, Transition, Grid } from 'semantic-ui-react';



class Project extends React.Component{
  constructor(props) {
    super(props);
    this.state = {isToggled: true};

    // Columns of the Grid used for project descriptions
    this.pDescGridColumns = 12;
    // Column width of the Project Description header
    this.pDescHeaderWidth = 3;
    // Column width of the Project Description content
    this.pDescContentWidth = 9;

    this.duration = 500;
    this.handleCardClick = this.handleCardClick.bind(this);
  }


  handleCardClick(){
    this.setState(prevState => ({
      isToggled: !prevState.isToggled
    }));
  }

  // componentDidUpdate(){
  //   console.log('componentDidUpdate: ' + this.state.isToggled);
  // }

  render(){
    const project = this.props.project;
    const {isToggled} = this.state;

    const icon = isToggled ? (
      <Button icon='caret down' size='tiny' floated='right' onClick={this.handleCardClick} />
    ) : (
      <Button icon='caret up' size='tiny' floated='right' onClick={this.handleCardClick} />
    );

    return(
      <Card>
        <Card.Content textAlign='left'>
          <Card.Header>{project.title}</Card.Header>
          <Card.Meta>
            <p>{project.time_period}</p>
            <p>{project.company_name}</p>
          </Card.Meta>
          <Card.Description>
            <Transition visible={!isToggled} animation='fade down' duration={this.duration}>
              <div className='App-project-detail-table' hidden={isToggled}>
                <Grid columns={this.pDescGridColumns}>
                  <Grid.Row>
                    <Grid.Column width={this.pDescHeaderWidth}>
                      <Header as='h4' textAlign='left'>
                        <FormattedMessage id='Project.Role' />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={this.pDescContentWidth}>
                      {project.role}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={this.pDescHeaderWidth}>
                      <Header as='h4' textAlign='left'>
                        <FormattedMessage id='Project.Branch' />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={this.pDescContentWidth}>
                      {project.branch}
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={this.pDescHeaderWidth}>
                      <Header as='h4' textAlign='left'>
                        <FormattedMessage id='Project.Tasks' />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={this.pDescContentWidth}>
                      <List items={project.tasks} />
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Column width={this.pDescHeaderWidth}>
                      <Header as='h4' textAlign='left'>
                        <FormattedMessage id='Project.SystemAndMethods' />
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={this.pDescContentWidth}>
                      <List items={project.systems_and_methods} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Transition>
          </Card.Description>
          {icon}
        </Card.Content>
      </Card>
    );
  }
}

Project.propTypes = {
  project: PropTypes.object
};

export default Project;