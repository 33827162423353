import React from 'react';

import './Styles/App.css';
import { Route, BrowserRouter } from 'react-router-dom';

import SiteHeader from './Components/SiteHeader';
import Home from './Components/Home';
import Contact from './Components/Contact';

import {addLocaleData, IntlProvider} from 'react-intl';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';

import localeDE from './Resources/de-DE';
import localeEN from './Resources/en-US';
import { flattenMessages } from './utils';

addLocaleData([...de, ...en]);

/* Define your default translations */
// let locale = (navigator.languages && navigator.languages[0]) || 
//             navigator.language ||
//             navigator.userLanguage ||
//             'de';

let i18nConfig = {
  locale: 'de-DE',
  messages: localeDE
};

class App extends React.Component {
  constructor(props) {
    super(props);

    this.imageSrc = 'https://gravatar.com/avatar/7efea8cae7b1480d5968bb468b7540ee';
    this.imageWidth = 200;
    this.imageHref = this.imageSrc + '?s=' + this.imageWidth;

    this.state = {
      locale: 'de-DE'
    };

    this.onChangeLanguage = this.onChangeLanguage.bind(this);
  }

  onChangeLanguage(lang) {
    //debugger;
    // let lang = e.target.lang === '' ? e.target.parentNode.lang : e.target.lang;
    switch (lang) {
    case 'de-DE': 
      i18nConfig.messages = localeDE;
      break;
    case 'en-US':
      i18nConfig.messages = localeEN;
      break;
    default: 
      i18nConfig.messages = localeDE;
      break;
    }
    if(this.state.locale !== lang)
      this.setState({ locale: lang });
    i18nConfig.locale = lang;
  }

  render() {
    const {locale} = this.state;
    return (
      <IntlProvider key={i18nConfig.locale} locale={i18nConfig.locale} messages={flattenMessages(i18nConfig.messages)}>
        <BrowserRouter>
          <div className='App'>
            <SiteHeader language={locale} changeLanguage={this.onChangeLanguage}/>

            <main className='App-content'>
              <Route exact path='/' render={() => <Home language={locale} />} />
              <Route path='/contact' component={Contact} />
            </main>
          </div>
        </BrowserRouter>
      </IntlProvider>
    );
  }
}

export default App;
