import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';


class Contact extends Component {
  render () {
    return (
      <article>
        <h2>
          <FormattedMessage id='Contact.Header' />
        </h2>
        <p>
          <FormattedMessage id='Contact.SubHeader' />
          <a href='mailto:l.cristea@gmx.de' target='_top'>
            <FormattedMessage id='Contact.LinkTitle' />
          </a>. 
        </p>
      </article>
    );
  }
}

export default Contact;
