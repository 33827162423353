export default class SkillData {}

SkillData.Skills = [
  {
    'name': 'C#',
    'rating': '8'
  },
  {
    'name': 'ASP.NET',
    'rating': '8'
  },
  {
    'name': 'HTML/CSS',
    'rating': '8'
  },
  {
    'name': 'JavaScript',
    'rating': '8'
  },
  {
    'name': 'SQL',
    'rating': '6'
  },
  {
    'name': 'C',
    'rating': '4'
  },
  {
    'name': 'C++',
    'rating': '5'
  },
  {
    'name': 'Java',
    'rating': '5'
  }
];