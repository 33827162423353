import React from 'react';
import PropTypes from 'prop-types';

import { List } from 'semantic-ui-react';
import Skill from './Skill';

class SkillList extends React.Component{
  constructor(props) {
    super(props);
  }

  
  render(){
    const isMobile = this.props.isMobile;
    const rows = [];
    this.props.skills.forEach((skill, i) => {
      rows.push(<Skill key={i} skill={skill} />);
    });
    
    return(
      <List className={isMobile ? '' : 'App-skillList'} items={rows}/>
    );
  }
}

SkillList.propTypes = {
  skills: PropTypes.array,
  isMobile: PropTypes.bool
};

export default SkillList;